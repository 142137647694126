import { gql } from "@apollo/client";

export default gql`
  query ($startTime: DateTime!, $endTime: DateTime!, $employeeId: ID!) {
    appointments(startTime: $startTime, endTime: $endTime, employeeId: $employeeId, status: [STATUS_SCHEDULED, STATUS_CONFIRMED]) {
      id
      appointmentType {
        id
        name
        category {
          id
          name
        }
      }
      relation {
        id
        name
        afasCode
      }
      inviteSentOn
      startTime
      endTime
      status
    }

    scheduledBreaks(employeeId: $employeeId, startDate: $startTime, endDate: $endTime) {
      id
      scheduledBreakRuleId
      startTime
      endTime
      isPristine
    }
  }
`;
