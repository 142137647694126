import { gql } from "@apollo/client";

export default gql`
  query AppointmentsQuery($relationId: ID!) {
    appointments(relationId: $relationId, status: [STATUS_SCHEDULED, STATUS_CONFIRMED]) {
      id
      appointmentType {
        id
        name
        category {
          id
          name
        }
      }
      relation {
        id
        name
        afasCode
      }
      inviteSentOn
      startTime
      endTime
      status
    }
  }
`;
