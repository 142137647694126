import { rootAppointmentType } from "@/functions/appointment-util";

const appointmentTypes = {
  Onderhoud: "orange",
  Installatie: "green",
  Demonstratie: "purple",
  Training: "red",
  Overig: "blue",
};

const today = new Date();
const twoMonthsEarlier = new Date(today.getFullYear(), today.getMonth() - 2, today.getDay());

export default function mapToColor(appointment: any) {
  // appointment is overdue
  if (new Date(appointment.prospectiveDate) <= twoMonthsEarlier && appointment.status === "STATUS_REQUESTED") {
    return ["nl_NL", "nl_BE"].includes(appointment.location.locale) ? "black" : "blueviolet";
  }

  const rootAppointmentTypeName = rootAppointmentType(appointment.appointmentType).name;
  if ("Onderhoud" === rootAppointmentTypeName) {
    if (appointment.appointmentType.name === "Correctief onderhoud n.a.v. inzet") {
      return "deeppink";
    }

    if (appointment.appointmentType.name === "Correctief onderhoud") {
      return "yellow";
    }

    if (appointment.appointmentType.name === "Onderhoud incl. AED-instructie") {
      return appointment.location.locale === "fr_BE" ? "pink" : "blueviolet";
    }

    return appointment.location.locale === "fr_BE" ? "grey" : "orange";
  }

  return appointmentTypes[rootAppointmentTypeName];
}
