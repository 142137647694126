import { Alert } from "antd";
import dayjs from "dayjs";
import { Fragment } from "react";

export interface AppointmentTimeWindowSidebarProps {
  displayTimeWindow: boolean;
  timeWindowStart: string | null;
  timeWindowEnd: string | null;
}

export function AppointmentTimeWindowSidebar({ displayTimeWindow, timeWindowStart, timeWindowEnd }: AppointmentTimeWindowSidebarProps) {
  return displayTimeWindow === false ? (
    <Fragment />
  ) : (
    <Alert
      message={
        timeWindowStart === null || timeWindowEnd === null
          ? "Deze afspraak is ingepland in een tijdsvak"
          : `Deze afspraak is ingepland in een tijdsvak tussen ${ft(timeWindowStart)} en ${ft(timeWindowEnd)}`
      }
      style={{
        marginTop: -12,
      }}
      type="info"
    />
  );
}

function ft(input: string) {
  return dayjs(input).format("HH:mm");
}
