import {
  BarChartOutlined,
  BarsOutlined,
  BookOutlined,
  CalendarOutlined,
  DisconnectOutlined,
  HomeOutlined,
  InboxOutlined,
  InfoOutlined,
  MailOutlined,
  MedicineBoxOutlined,
  NodeIndexOutlined,
  PauseCircleOutlined,
  PlusOutlined,
  ProfileOutlined,
  ScheduleOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import ScheduleAppointment from "./Appointment/AppointmentSchedule";
import OverdueAppointments from "./Appointment/OverdueAppointments";
import PendingAppointments from "./Appointment/PendingAppointments";
import RequestAppointment from "./Appointment/RequestAppointment";
import ScheduledBreaksList from "./Appointment/ScheduledBreaksList";
import RegisterContract from "./Contract/RegisterContract";
import ViewContract from "./Contract/ViewContract";
import Dashboard from "./Dashboard";
import ListEmailTemplates from "./EmailTemplates/ListEmailTemplates";
import ListIssues from "./IssueTracker/ListIssues";
import SearchProduct from "./Product/SearchProduct";
import { RelationCustomerScreen } from "./Relation/Customer/RelationCustomerScreen";
import { RelationDealerScreen } from "./Relation/Dealer/RelationDealerScreen";
import { RelationImportScreen } from "./Relation/Import/RelationImportScreen";
import ListAvailableReports from "./Report/ListAvailableReports";
import ListUsers from "./User/ListUsers";
import ViewWorksheet from "./Worksheet/ViewWorksheet";
import ViewWorksheets from "./Worksheet/ViewWorksheets";

const routes = [
  {
    path: "",
    exact: true,
    inMenu: true,
    icon: <HomeOutlined />,
    name: "Dashboard",
    element: <Dashboard />,
  },
  {
    path: "issues",
    inMenu: true,
    icon: <WarningOutlined />,
    name: "Meldingen",
    element: <ListIssues />,
  },
  {
    path: "relations",
    inMenu: true,
    icon: <TeamOutlined />,
    name: "Relaties",
    children: [
      {
        path: "import",
        name: "Importeren",
        icon: <PlusOutlined />,
        inMenu: true,
        element: <RelationImportScreen />,
      },
      {
        path: "dealer/:relationId?",
        href: "dealer",
        name: "Dealer-relaties",
        icon: <NodeIndexOutlined />,
        inMenu: true,
        element: <RelationDealerScreen />,
      },
      {
        path: "customer/:relationId?/locations?/:locationId?",
        href: "customer",
        name: "Klant-relaties",
        icon: <InfoOutlined />,
        inMenu: true,
        element: <RelationCustomerScreen />,
      },
    ],
  },
  {
    path: "contracts",
    inMenu: true,
    icon: <BookOutlined />,
    name: "Contracten",
    children: [
      {
        path: "register",
        name: "Registreren",
        icon: <PlusOutlined />,
        inMenu: true,
        element: <RegisterContract />,
        exact: true,
      },
      {
        path: ":contractId",
        name: "Bekijken",
        icon: <ProfileOutlined />,
        element: <ViewContract />,
        inMenu: false,
      },
    ],
  },
  {
    path: "appointments",
    inMenu: true,
    icon: <CalendarOutlined />,
    name: "Afspraken",
    children: [
      {
        path: "request",
        name: "Aanvragen",
        icon: <PlusOutlined />,
        inMenu: true,
        element: <RequestAppointment />,
      },
      {
        path: "pending",
        name: "Inplannen op kaart",
        icon: <InboxOutlined />,
        inMenu: true,
        element: <PendingAppointments />,
      },
      {
        path: "schedule",
        name: "Agenda",
        icon: <ScheduleOutlined />,
        inMenu: true,
        element: <ScheduleAppointment />,
      },
      {
        path: "overdue",
        name: "Achterstallig",
        icon: <DisconnectOutlined />,
        inMenu: true,
        element: <OverdueAppointments />,
      },
      {
        path: "scheduled-breaks",
        name: "Pauzeschema",
        icon: <PauseCircleOutlined />,
        inMenu: true,
        element: <ScheduledBreaksList />,
      },
    ],
  },
  {
    path: "worksheets",
    name: "Onderhoudsbeurten",
    icon: <ToolOutlined />,
    inMenu: true,
    children: [
      {
        path: "",
        name: "Overzicht",
        icon: <BookOutlined />,
        inMenu: true,
        element: <ViewWorksheets />,
        exact: true,
      },
      {
        path: ":worksheetId",
        name: "Bekijken",
        icon: <BarsOutlined />,
        element: <ViewWorksheet />,
        inMenu: false,
      },
    ],
  },
  {
    path: "users",
    inMenu: true,
    icon: <UserOutlined />,
    name: "Gebruikers",
    element: <ListUsers />,
  },
  {
    path: "products",
    inMenu: true,
    icon: <MedicineBoxOutlined />,
    name: "Producten",
    element: <SearchProduct />,
  },
  {
    inMenu: true,
    icon: <MailOutlined />,
    name: "E-mail templates",
    path: "email-templates",
    element: <ListEmailTemplates />,
  },
  {
    path: "reports",
    inMenu: true,
    icon: <BarChartOutlined />,
    name: "Rapportages",
    element: <ListAvailableReports />,
  },
];

export default routes;
