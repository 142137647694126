import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Form, Modal, notification, Skeleton } from "antd";

import filterTouchedValues from "@/functions/filter-touched-values";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import { RelationQuery } from "../../../graphql/RelationQuery";
import LocationForm from "./LocationForm";

interface EditLocationModal {
  onClose: () => void;
  relationId: string;
  locationId: string;
}

export default function EditLocationModal({ onClose, relationId, locationId }: EditLocationModal) {
  const [form] = Form.useForm();

  const [updateLocationAsync, { loading: isUpdating }] = useMutation(UpdateLocationMutation);
  const { data } = useQuery(RelationQuery, { variables: { id: relationId } });
  const location = data?.relation?.locations.find(location => location.id === locationId);

  const handleOnSubmit = async values => {
    const updatedProps = filterTouchedValues(form.isFieldTouched, values);
    if (Object.keys(updatedProps).length < 1) return onClose();

    const { id: _locationId, address, addressExtraDetails, ...updateableProps } = updatedProps;

    try {
      await updateLocationAsync({
        variables: {
          input: {
            relationId,
            locationId,
            ...updateableProps,
            address:
              undefined !== address || undefined !== addressExtraDetails
                ? { ...(address ?? values.address), extraDetails: addressExtraDetails ?? values.addressExtraDetails }
                : undefined,
          },
        },
      });

      notification.success({ message: "Locatiegegevens gewijzigd" });
      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal
      centered
      confirmLoading={isUpdating}
      onCancel={onClose}
      onOk={() => form.submit()}
      open
      title="Locatie bewerken"
      styles={{
        body: {
          overflowY: "auto",
          maxHeight: window.innerHeight - 168,
        },
      }}
    >
      {undefined === location ? (
        <Skeleton />
      ) : (
        <LocationForm
          form={form}
          onFinish={handleOnSubmit}
          showAddressCorrectionHint
          initialValues={{
            ...location,
            addressExtraDetails: location.address.extraDetails,
            address: {
              ...location.address,
              coordinates: undefined,
              __typename: undefined,
            },
            __typename: undefined,
          }}
        />
      )}
    </Modal>
  );
}

const UpdateLocationMutation = gql`
  mutation ($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      location {
        id
        name
        afasCode
        contactPerson
        primaryEmail
        secondaryEmail
        phoneNumber
        mobilePhoneNumber
        locale
        planningComment
        onSiteComment
        invoiceComment
        address {
          street
          extraDetails
          postalCode
          city
          country
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;
