import { gql, useQuery } from "@apollo/client";
import { Card, Empty, List, Skeleton } from "antd";
import dayjs from "dayjs";

import { useAuthentication } from "@/authentication";
import { formatAppointmentType } from "@/functions/appointment-util";

interface AppointmentRemindMeCardProps {
  onClick: (appointmentId: string) => void;
}

export function AppointmentRemindMeCard({ onClick }: AppointmentRemindMeCardProps) {
  // eslint-disable-next-line prettier/prettier
  const { user: { id: userId } } = useAuthentication();

  const { data, loading } = useQuery(AppointmentRemindMeQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      employeeId: userId,
    },
  });

  const reminders = data?.appointmentRemindMes ?? [];
  const today = dayjs();
  const tomorrow = today.add(1, "day");

  // eslint-disable-next-line prettier/prettier
  const itemsToday = reminders.filter(x => (dayjs(x.remindAt).isSame(today, "day") || dayjs(x.remindAt).isBefore(today, "day")));
  const itemsTomorrow = reminders.filter(x => dayjs(x.remindAt).isSame(tomorrow, "day"));
  const itemsLater = reminders.filter(x => dayjs(x.remindAt).isAfter(tomorrow, "day"));

  const renderItem = item => {
    const time = dayjs(item.appointment.startTime ?? item.appointment.prospectiveDate).format("DD/MM/YYYY");
    const description = formatAppointmentType(item.appointment.appointmentType);
    const relation = formatRelationName(item.appointment.relation);

    return (
      <List.Item onClick={() => onClick(item.appointment.id)} style={{ columnGap: 8 }}>
        <List.Item.Meta title={`${time} - ${description} @ ${relation}, ${item.appointment.location.name}`} />
      </List.Item>
    );
  };

  return (
    <Card bodyStyle={{ padding: false === loading ? 0 : undefined }} title="Afspraak herinneringen">
      {loading ? (
        <Skeleton />
      ) : reminders.length > 0 ? (
        <>
          {itemsToday.length > 0 && <List bordered dataSource={itemsToday} header="Vandaag" renderItem={renderItem} />}
          {itemsTomorrow.length > 0 && <List bordered dataSource={itemsTomorrow} header="Morgen" renderItem={renderItem} />}
          {itemsLater.length > 0 && <List bordered dataSource={itemsLater} header="Later" renderItem={renderItem} />}
        </>
      ) : (
        <Empty />
      )}
    </Card>
  );
}

function formatRelationName(relation) {
  return relation.afasCode !== null ? `(${relation.afasCode}) ${relation.name}` : relation.name;
}

const AppointmentRemindMeQuery = gql`
  query ($employeeId: ID!) {
    appointmentRemindMes(employeeId: $employeeId) {
      id
      appointment {
        id
        appointmentType {
          id
          name
        }
        relation {
          id
          afasCode
          name
        }
        location {
          id
          name
        }
        prospectiveDate
        startTime
        status
      }
      remindAt
    }
  }
`;
