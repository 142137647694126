import { gql } from "@apollo/client";

export default gql`
  mutation ($id: ID!, $displayTimeWindow: Boolean!) {
    sendNotification(input: { id: $id, displayTimeWindow: $displayTimeWindow }) {
      appointment {
        id
        inviteSentOn
      }
    }
  }
`;
