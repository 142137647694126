import { gql, useQuery } from "@apollo/client";
import { Divider, Space, Table } from "antd";
import * as React from "react";

import { isInstallationAppointment } from "@/functions/appointment-util";
import formatProductDescription from "@/functions/format-product-description";

interface ProductReplaceable {
  id: string;
  productType: {
    id: string;
    description: string;
  };
}

function groupReplaceableProducts(products: ProductReplaceable[]) {
  const retVal: Record<string, ProductReplaceable & { amount: number }> = {};

  for (const product of products) {
    if (product.productType.id in retVal) {
      retVal[product.productType.id].amount += 1;
      continue;
    }

    retVal[product.productType.id] = {
      ...product,
      amount: 1,
    };
  }

  return Object.values(retVal);
}

export default function ProductsTables({ appointment }) {
  const { data: productsSummaryData, loading: productsSummaryLoading } = useQuery(ProductsSummaryQuery, {
    variables: { relationId: appointment.relation.id, locationId: appointment.location.id },
    skip: isInstallationAppointment(appointment),
  });

  const { data: productsReplaceableData, loading: productsReplaceableLoading } = useQuery<{
    productsReplaceable: ProductReplaceable[];
  }>(ProductsReplaceableQuery, {
    variables: { locationId: appointment.location.id, onDate: appointment.startTime ?? appointment.prospectiveDate },
  });

  const groupedReplaceableProducts = React.useMemo(
    () => groupReplaceableProducts(productsReplaceableData?.productsReplaceable ?? []),
    [productsReplaceableData]
  );

  return (
    <>
      <Divider>Producten</Divider>
      <Space direction="vertical" size="middle">
        <Table
          columns={[
            {
              ellipsis: true,
              title: "Product type",
              render: product => formatProductDescription(product),
            },
            {
              align: "right",
              title: "Aantal",
              dataIndex: "amount",
              width: "15%",
            },
          ]}
          dataSource={productsSummaryData?.productsSummary ?? []}
          loading={productsSummaryLoading}
          pagination={productsSummaryData?.productsSummary?.length > 10 ? undefined : false}
          rowKey={row => (row as any).productType.id}
          title={() => "Producten op locatie"}
          size="small"
        />
        {groupedReplaceableProducts.length > 0 && (
          <Table
            columns={[
              {
                ellipsis: true,
                title: "Product type",
                render: product => formatProductDescription(product),
              },
              {
                align: "right",
                title: "Aantal",
                dataIndex: "amount",
                width: "15%",
              },
            ]}
            dataSource={groupedReplaceableProducts}
            loading={productsReplaceableLoading}
            pagination={groupedReplaceableProducts.length > 10 ? undefined : false}
            rowKey={row => (row as any).id}
            title={() => "Producten aan vervanging/ groot onderhoud toe"}
            size="small"
          />
        )}
        {appointment.consumableGoods.length > 0 && (
          <Table
            columns={[
              {
                title: "Product type",
                dataIndex: ["productType", "description"],
              },
              {
                align: "right",
                title: "Aantal",
                dataIndex: "amount",
                width: "15%",
              },
            ]}
            dataSource={appointment.consumableGoods}
            pagination={appointment.consumableGoods.length > 10 ? undefined : false}
            rowKey={row => (row as any).id}
            title={() => "Producten mee te nemen"}
            size="small"
          />
        )}
      </Space>
    </>
  );
}

const ProductsSummaryQuery = gql`
  query ($relationId: ID!, $locationId: ID!) {
    productsSummary(relationId: $relationId, locationId: $locationId, skipDestroyed: true) {
      productType {
        id
        code
        description
      }
      amount
    }
  }
`;

const ProductsReplaceableQuery = gql`
  query ($locationId: ID!, $onDate: DateTime!) {
    productsReplaceable(locationId: $locationId, onDate: $onDate) {
      id
      productType {
        id
        code
        description
      }
    }
  }
`;
