import { PlusOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { Button, Card, Checkbox, Col, Form, Modal, notification, Row, Space } from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { RRule } from "rrule";

import MonthPicker from "@/components/MonthPicker";
import PageHeader from "@/components/PageHeader";
import RelationPicker from "@/components/RelationPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import CreateVisitCard from "./components/CreateVisitCard";
import MaybePriorContractAlert from "./components/MaybePriorContractAlert";
import { mapFormValuesToFlags, removeFlagsFromFormValues } from "./mapFormValuesToFlags";

interface RegisterContractFormValues {
  startingOn: dayjs.Dayjs;
  endingOn?: dayjs.Dayjs;
  visits: Array<{
    appointmentTypeId: string;
    endingOn?: dayjs.Dayjs;
    overrideDefaultFlags: boolean;
    flags?: Record<string, boolean>;
    interval: number;
    repeat: string[];
    startingOn: dayjs.Dayjs;
    locationIds: string[];
    comment?: string;
  }>;
}

const DEFAULT_VISIT_VALUE = {
  startingOn: dayjs().startOf("month"),
  interval: 1,
  overrideDefaultFlags: false,
};

export default function RegisterContract() {
  const [registerContractAsync, { loading }] = useMutation(RegisterContractMutation);
  const [formInstance] = Form.useForm<RegisterContractFormValues>();
  const [relationId, setRelationId] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (undefined === relationId) return;

    formInstance.resetFields();
    formInstance.setFieldValue("visits", [DEFAULT_VISIT_VALUE]);
  }, [relationId]);

  const handleOnSubmit = async (values: RegisterContractFormValues) => {
    try {
      const flatMappedVisits = values.visits.flatMap(visit_ => {
        const visit = removeFlagsFromFormValues(visit_);
        const [flags] = visit.overrideDefaultFlags ? mapFormValuesToFlags(visit_, "flags") : [[], undefined];

        const recurrence = new RRule({
          interval: visit.interval,
          bymonth: [visit.startingOn.month() + 1],
          freq: RRule.YEARLY,
        }).toString();

        return visit.locationIds.map(locationId => {
          const { locationIds, interval, repeat, ...restProps } = visit;

          return {
            ...restProps,
            flags,
            locationId,
            recurrence,
          };
        });
      });

      if (flatMappedVisits.length < 1) {
        return notification.error({ message: "Voeg tenminste 1 bezoek toe" });
      }

      // eslint-disable-next-line no-inner-declarations
      async function _handleOnSubmit() {
        try {
          const [defaultFlags] = mapFormValuesToFlags(values, "defaultFlags");

          await registerContractAsync({
            variables: {
              ...removeFlagsFromFormValues(values, "defaultFlags"),
              relationId,
              defaultFlags,
              visits: flatMappedVisits,
            },
          });

          notification.success({ message: "Contract is aangemaakt!" });
          formInstance.resetFields();
        } catch (error) {
          mapGraphQLErrorsToNotifications(error as ApolloError);
        }
      }

      const allLocationIds = flatMappedVisits.map(v => v.locationId);
      if ([...new Set(allLocationIds)].length !== allLocationIds.length) {
        Modal.confirm({
          title: "Een of meerdere locaties zijn meerdere keren ingevuld. Klopt dit?",
          onOk: () => _handleOnSubmit(),
        });

        return;
      }

      _handleOnSubmit();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <>
      <PageHeader bordered title="Zoek een relatie">
        <RelationPicker
          autoFocus
          onChange={value => setRelationId(value)}
          dropdownMatchSelectWidth={false}
          style={{ width: 250 }}
          value={relationId}
        />
      </PageHeader>

      {undefined !== relationId && (
        <Form<RegisterContractFormValues> form={formInstance} onFinish={handleOnSubmit}>
          <Space direction="vertical" style={{ marginTop: 10 }}>
            <MaybePriorContractAlert relationId={relationId} />

            <Card title="Algemene contractgegevens">
              <Row gutter={32}>
                <Col span={10}>
                  <Form.Item labelCol={{ span: 4 }} label="Relatie">
                    <RelationPicker disabled value={relationId} />
                  </Form.Item>
                  <Form.Item labelCol={{ span: 4 }} label="Startdatum" name="startingOn" required rules={[{ required: true }]}>
                    <MonthPicker />
                  </Form.Item>
                  <Form.Item labelCol={{ span: 4 }} label="Einddatum" name="endingOn">
                    <MonthPicker />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Standaard instellingen">
                    <Form.Item name="defaultFlags.FLAG_INCLUDES_DISPOSABLES" valuePropName="checked" style={{ marginBottom: 0 }}>
                      <Checkbox>Inclusief disposables</Checkbox>
                    </Form.Item>
                    <Form.Item name="defaultFlags.FLAG_INVOICE_AFTERWARDS" valuePropName="checked" style={{ marginBottom: 0 }}>
                      <Checkbox>Onderhoudsbeurt los factureren (geen abonnement)</Checkbox>
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Form.List name="visits">
              {(elements, { add, remove }) => (
                <Space direction="vertical">
                  {elements.map(({ key, name: formIndex }) => (
                    <CreateVisitCard key={key} formIndex={formIndex} onRemoveClick={() => remove(formIndex)} relationId={relationId} />
                  ))}
                  <Space direction="horizontal">
                    <Button onClick={() => add(DEFAULT_VISIT_VALUE)} icon={<PlusOutlined />}>
                      Nog een bezoek toevoegen
                    </Button>
                  </Space>
                </Space>
              )}
            </Form.List>

            <Button loading={loading} type="primary" htmlType="submit">
              Contract aanmaken
            </Button>
          </Space>
        </Form>
      )}
    </>
  );
}

const RegisterContractMutation = gql`
  mutation ($relationId: ID!, $startingOn: DateTime!, $defaultFlags: [ContractFlag!], $visits: [SignContractVisitInput!]!) {
    registerContract(input: { relationId: $relationId, startingOn: $startingOn, defaultFlags: $defaultFlags, visits: $visits }) {
      contract {
        id
      }
    }
  }
`;
