import { buildMenuItems, exampleSetup } from "prosemirror-example-setup";
import { defaultMarkdownParser, defaultMarkdownSerializer, schema } from "prosemirror-markdown";
import { Fragment, Node, Slice } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { forwardRef, useImperativeHandle, useLayoutEffect, useRef } from "react";

interface MarkdownTextInputProps {
  onChange?: (value: string) => void;
  initialValue?: string;
}

interface MarkdownTextInputRef {
  focus: () => void;
  reset: () => void;
  setValue: (input: string) => void;
}

function createStateFromValue(initialValue?: string) {
  const { inlineMenu, blockMenu } = buildMenuItems(schema);

  return EditorState.create({
    doc: defaultMarkdownParser.parse(initialValue ?? "") ?? undefined,
    plugins: exampleSetup({
      schema,
      menuContent: [...inlineMenu, ...blockMenu],
    }),
  });
}

function MarkdownTextInput({ onChange, initialValue }: MarkdownTextInputProps, ref: React.ForwardedRef<MarkdownTextInputRef>) {
  const inputElementRef = useRef<HTMLDivElement>(null);
  const editorView = useRef<EditorView>();

  useImperativeHandle(
    ref,
    () => ({
      focus: () => editorView.current?.focus(),
      reset: () => editorView.current?.updateState(createStateFromValue(initialValue)),
      setValue: (value: string) => editorView.current?.updateState(createStateFromValue(value)),
    }),
    [initialValue]
  );

  useLayoutEffect(() => {
    if (!inputElementRef.current) {
      return;
    }

    editorView.current = new EditorView(inputElementRef.current, {
      // Trigger the `onChange` event from React props
      dispatchTransaction(tr) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        editorView.current!.updateState(editorView.current!.state.apply(tr));
        handleOnChange();
      },
      // Do not allow images to be copy pasted
      transformPasted(slice) {
        const nextFragments: Node[] = [];

        for (let i = 0; i < slice.content.childCount; ++i) {
          const element = slice.content.child(i);

          if (element?.type.name !== "image") {
            nextFragments.push(element);
          }
        }

        return Slice.maxOpen(Fragment.from(nextFragments));
      },
      state: createStateFromValue(initialValue),
    });

    return () => editorView.current?.destroy();
  }, []);

  const handleOnChange = () => {
    if (!editorView.current) return;
    onChange?.(defaultMarkdownSerializer.serialize(editorView.current.state.doc));
  };

  return (
    <div
      ref={inputElementRef}
      style={{
        background: "white",
        color: "black",
        backgroundClip: "padding-box",
        borderRadius: 4,
        border: "1px solid rgba(0, 0, 0, 0.2",
        padding: "5px 0",
      }}
    />
  );
}

export default forwardRef(MarkdownTextInput);
